@import "../../assets/scss/colors.scss";
@import "../../assets/scss/fonts.scss";

.products-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 1.875rem 1.875rem 0.5rem;
  position: relative;

  .btn-group {
    border: 1px solid $lighter-gray;
    padding: 4px;
    border-radius: 41px;

    .toggle-btn,
    .toggle-btn.btn:nth-child(n + 3),
    .toggle-btn.btn:not(:last-child):not(.dropdown-toggle) {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 25px;
    }

    input {
      &.btn-check+.btn {
        background-color: $white;
        border: none;
        color: $black;
      }

      &.btn-check:checked+.btn {
        color: $white;
        border: none;

        &.blue {
          background-color: $brand-blue;
        }

        &.green {
          background-color: $brand-green;
        }

        &.gray {
          background-color: $brand-gray;
        }

        &.orange {
          background-color: $brand-orange;
        }
      }
    }
  }

  .dropdown {
    &.blue {
      button {
        &.btn-primary {
          border: 1px solid $brand-blue;
          background-color: $brand-blue;
        }
      }
    }

    &.green {
      button {
        &.btn-primary {
          border: 1px solid $brand-green;
          background-color: $brand-green;
        }
      }
    }

    &.gray {
      button {
        &.btn-primary {
          border: 1px solid $brand-gray;
          background-color: $brand-gray;
        }
      }
    }

    &.orange {
      button {
        &.btn-primary {
          border: 1px solid $brand-orange;
          background-color: $brand-orange;
        }
      }
    }
  }

  .alert-message {
    background-color: $warning-light;
    border: 1px solid $warning-dark;
    border-radius: 0.5rem;
    font-family: $primary-font-lean;
    font-size: 0.875rem;
    padding: 0.75rem 0.75rem 0.5rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 26rem;

    span {
      font-family: $primary-font;
    }
  }
}

.loading {
  padding: 0.4524rem 0px;

  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ellipsis steps(4, end) 1000ms infinite;
    content: "\2026";
    width: 0px;
  }

  &:before {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ellipsis steps(4, end) 1000ms infinite;
    content: '';
    width: 0px;
  }
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}