.swal2-container {
  .swal2-popup {
    padding: 1em 1em 1.25em;

    .swal2-header {
      background-color: $brand-green;
      border-radius: 5px 5px 0 0;

      .swal2-title {
        align-items: center;
        color: $brand-gray;
        font-family: $primary-font-bold;
        font-size: 1.5em;
        height: 2.25em;
        margin: 0;
        padding-top: 6px;
        text-transform: uppercase;
      }

      .swal2-icon {
        &.swal2-success {

          .swal2-success-circular-line-left,
          .swal2-success-circular-line-right,
          .swal2-success-fix {
            background-color: transparent !important;
          }
        }
      }
    }

    .swal2-content {
      font-family: $primary-font;
      padding: 30px 0 10px;

      strong {
        display: block;
        font-family: $primary-font;
        font-size: 1.25em;
      }
    }

    .swal2-html-container {
      .not-exists-message {
        line-height: 2;
        text-align: left;

        ul {
          flex-direction: column;
          justify-content: flex-start;
          list-style: disc;
          text-align: left;

          li {
            font-family: $primary-font-bold;
          }
        }

        p {
          font-size: 1rem;
        }
      }
    }

    .swal2-actions {
      button {
        background-color: $brand-blue;
      }
    }
  }
}