body {
  font-family: $primary-font;
  overflow: hidden;
}

.link {
  background-color: transparent;
  border: none;
  color: $brand-blue;
  text-decoration: underline;

  &.blue {
    color: $brand-blue;
  }

  &.green {
    color: $brand-green;
  }

  &.red {
    color: $danger;
  }
}

/**
 * ================================
 * Processing dots - inline loader
 * ================================
 */
.processing {
  animation: processing 0.5s infinite linear alternate;
  animation-delay: 0.25s;
  background-color: $brand-orange;
  border-radius: 50%;
  height: 10px;
  margin: auto;
  position: relative;
  width: 10px;

  &::before {
    animation: processing 0.5s infinite alternate;
    animation-delay: 0s;
    background-color: $brand-orange;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 10px;
    left: -15px;
    position: absolute;
    top: 0;
    width: 10px;
  }

  &::after {
    animation: processing 0.5s infinite alternate;
    animation-delay: 0.5s;
    background-color: $brand-orange;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 10px;
    left: 15px;
    position: absolute;
    top: 0;
    width: 10px;
  }
}

@keyframes processing {
  0% { background-color: $brand-orange; }
  50%, 100% { background-color: rgba(9, 92, 211, 0.2); }
}