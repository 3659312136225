@import 'assets/scss/colors.scss';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;

  .content {
    flex: 1 0 auto;
  }

  .footer {
    background-color: $brand-orange;
    bottom: 0;
    color: $white;
    flex-shrink: 0;
    padding: 15px;
    position: absolute;
    text-align: center;
    width: 100%;
  }
}