@import '../../assets/scss/colors.scss';

$class_color_map: (
  'blue': $brand-blue,
  'bluenc': $brand-blue,
  'gray': $brand-gray,
  'green': $brand-green,
  'orange': $brand-orange,
  'danger': $danger
);

@each $class,
$color in $class_color_map {
  .#{$class}-icon {
    vertical-align: -0.25em;
    font-size: 1.5rem;
    color: $color;
  }

  .#{$class}-btn {
    background-color: $color;
    color: $white;
    margin-right: 1rem;

    &:hover,
    &:active {
      background-color: darken($color: $color, $amount: 5%);
      color: $white;
    }
  }
}

.migration-page {
  .migration-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 1.875rem 1.875rem 0.5rem;
  }

  .compare-plans {
    button {
      text-transform: capitalize;

      &.comparing {
        background-color: transparent;
        border: none;
        color: $brand-blue;
        cursor: default;
      }

      &.identical {
        background-color: transparent;
        border: none;
        color: $brand-green;
        text-decoration: underline;
      }

      &.different {
        background-color: transparent;
        border: none;
        color: $danger;
        text-decoration: underline;
      }
    }
  }
}