@font-face {
  font-family: 'BlinkFitness_Balanced';
  src: url('../fonts/blinkfitness_balanced-webfont.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/blinkfitness_balanced-webfont.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/blinkfitness_balanced-webfont.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/blinkfitness_balanced-webfont.woff') format('woff'),
    /* Pretty Modern Browsers */
    url('../fonts/blinkfitness_balanced-webfont.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/blinkfitness_balanced-webfont.svg#svgFontName') format('svg');
  /* Legacy iOS */
}

@font-face {
  font-family: 'BlinkFitness_Lean';
  src: url('../fonts/blinkfitness_lean-webfont.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/blinkfitness_lean-webfont.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/blinkfitness_lean-webfont.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/blinkfitness_lean-webfont.woff') format('woff'),
    /* Pretty Modern Browsers */
    url('../fonts/blinkfitness_lean-webfont.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/blinkfitness_lean-webfont.svg#svgFontName') format('svg');
  /* Legacy iOS */
}

@font-face {
  font-family: 'BlinkFitness_Strong';
  src: url('../fonts/blinkfitness_strong-webfont.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/blinkfitness_strong-webfont.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/blinkfitness_strong-webfont.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/blinkfitness_strong-webfont.woff') format('woff'),
    /* Pretty Modern Browsers */
    url('../fonts/blinkfitness_strong-webfont.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/blinkfitness_strong-webfont.svg#svgFontName') format('svg');
  /* Legacy iOS */
}

.BlinkFitness_Strong {
  font-family: 'BlinkFitness_Strong', sans-serif;
}

.BlinkFitness_Lean {
  font-family: 'BlinkFitness_Lean', sans-serif;
}

.BlinkFitness_Balanced {
  font-family: 'BlinkFitness_Balanced', sans-serif;
}

$primary-font: 'BlinkFitness_Balanced', 'HelveticaNeue', Helvetica, Arial, sans-serif;
$primary-font-bold: 'BlinkFitness_Strong', 'HelveticaNeue', Helvetica, Arial, sans-serif;
$primary-font-lean: 'BlinkFitness_Lean', 'HelveticaNeue', Helvetica, Arial, sans-serif;
