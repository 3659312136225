.navbar {
  background-color: #fdfdfd !important;
  box-shadow: #ddd 0px -1px 0 inset;
  padding: 0 30px;

  .navbar-brand {
    color: $brand-gray;
    font-size: 2.25em;
    font-family: $primary-font-bold;
    display: flex;
    align-items: center;
    background-image: url('../images/blink_logo.png');
    background-repeat: no-repeat;
    background-size: 100px auto;
    background-position: 0 center;
    width: 240px;
    position: relative;

    &:after {
      content: 'admin';
      left: 110px;
      position: absolute;
      top: 12px;
    }
  }

  .navbar-collapse {
    .navbar-nav {
      padding-left: 30px;

      :is(a, button) {
        border: none;
        color: $brand-gray;
        font-weight: 600;
        padding: 2em 1em;
        text-decoration: none;

        &.active {
          background-color: $white;
          border-color: #ddd;
          border-style: solid;
          border-width: 0 1px;
        }

        &:after {
          background-color: transparent;
        }

        &:hover {
          color: $black;
          text-decoration: none;
        }
      }
    }

    form {
      margin-bottom: 0.5em;
      margin-top: 0.5em;

      button {
        margin-top: 0.5em;
      }
    }
  }
}

@media (min-width: 992px) {
  .navbar {
    .navbar-collapse {
      .navbar-nav {
        .nav-link {
          margin-bottom: 0;
          margin-top: 0;
        }
      }

      form {
        margin-bottom: 0;
        margin-top: 0;

        button {
          margin-top: 0;
        }
      }
    }
  }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 640px) {
  .navbar {
    padding: 0 1rem;

    .navbar-brand {
      font-size: 1.875rem;
      background-size: 5rem auto;
      width: 10rem;

      &:after {
        left: 5.25rem;
        top: 8px;
      }
    }

    .navbar-collapse {
      .navbar-nav {
        :is(a, button) {
          padding: 1em;
        }
      }
    }

    .sign-out {
      span {
        display: none;
      }
    }
  }
}