.range-picker {
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 100%;

  .rdrDefinedRangesWrapper {
    border-radius: 5px 0 0 5px;
  }

  .rdrCalendarWrapper {
    border-radius: 0 5px 5px 0;
  }

  .rdrDateDisplayWrapper {
    border-radius: 0 5px 0 0;
  }

  .rdrDateRangePickerWrapper {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: absolute;
    box-shadow: 1px 1px 5px #ccc;
    border: 1px solid #aaa;
    border-radius: 5px;
  }

  .rdrDefinedRangesWrapper {
    border-radius: 5px 0 0 0;
  }
}
