.form-check {
  cursor: pointer;
  font-size: 1.5rem;
  margin-top: 1rem;

  input[type='checkbox'] {
    border-width: 2px;
  }

  label {
    cursor: pointer;
    font-size: 1rem;
  }
}