$brand-blue: #095cd3;
$brand-blue-dark: #0034a1;
$brand-orange: #f52900;
$brand-orange-dark: #c30f00;
$brand-gray: #4a4f54;
$brand-green: #00a676;
$brand-green-dark: #008d64;
$dark-gray: #222222;
$disabled-gray: #b3b3b3;
$light-gray-1: #e5e5e5;
$light-gray-2: #cccccc;
$lightest-gray: #f5f5f5;
$lighter-gray: #99aab5;
$danger: #f52900;
$warning-light: #ffecb3;
$warning-dark: #ffdf80;

$blue-light: #def7ff;
$gray-light: #edf0f2;
$green-light: #f6f9e3;
$orange-light: #fef0eb;

$light-gray: #fafafa;

$white: #ffffff;
$black: #000000;