button {
  &.btn-primary {
    background-color: $brand-blue;
  }

  &.MuiButton-containedPrimary {
    background-color: $brand-blue;
    font-family: $primary-font;
    text-transform: none;
  }
}