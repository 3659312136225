[data-amplify-authenticator] {
  background: rgb(9, 92, 211);
  background: linear-gradient(135deg, rgba(9, 92, 211, 1) 0%, rgba(33, 108, 215, 1) 15%, rgba(82, 140, 224, 1) 25%, rgba(255, 255, 255, 1) 50%, rgba(247, 83, 51, 1) 75%, rgba(246, 62, 25, 1) 85%, rgba(245, 41, 0, 1) 100%);
  display: flex;
  height: 100%;
  justify-content: center;
}

[data-amplify-form] {
  .amplify-button--primary {
    background-color: $brand-blue;
  }
}

[data-amplify-footer] .amplify-button--link {
  color: $brand-blue;
}

+footer {
  display: none;
}

.sign-out {
  display: flex;
  justify-content: flex-end;
  border-radius: 4px;
  span {
    padding-left: 0.5rem;
  }
}