.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500px;

  .error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 250px;
    width: 850px;

    h3 {
      padding-bottom: 0.8rem;
    }

    h5 {
      padding-bottom: 1.2rem;
    }
  }
}