ul {
  list-style: none;
  display: flex;
  flex-direction: row;
}

table {
  tbody {
    font-size: 14px;
  }
}

.MuiPaper-root {
  font-family: var(--primary-font);

  .MuiTypography-root {
    font-family: inherit;
  }
  
  .MuiAlert-standardInfo {    
    background-color: transparent;
    color: $white;
    font-family: $primary-font;
    left: 2rem;
    position: absolute;
    width: max-content;
  }

  .MuiFormControl-root {
    :is(label, div, p) {
      font-family: inherit;
    }
  }

  .MuiToolbar-root {
    border-bottom: 1px solid $lighter-gray;
    &:last-child {
      display: none;
    }
  }

  .MuiTypography-h6 {
    color: #4a4f54;
    font-family: var(--primary-font-strong);
    font-size: 1em;
    padding-top: 5px;
  }

  .MuiTable-root {
    thead {
      tr {
        th {
          background-color: #f8f8f8;
          border-bottom: 2px solid #ccc;
          font-family: var(--primary-font);
          font-size: 12px;
          padding: 5px 10px 0;

          &.MuiTableCell-paddingNone {
            padding-top: 0;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          font-family: var(--primary-font);
          font-size: 1rem;
        }
        &:nth-child(even) {
          background-color: $lightest-gray;
        }
      }
    }
  }

  .MuiIconButton-root {
    &:hover {
      background-color: transparent;
    }
  }

  .Mui-checked {
    &.MuiCheckbox-colorPrimary {
      color: #095cd3;

      &.Mui-disabled {
        color: rgba(0, 0, 0, 0.26);
      }
    }

    &.MuiCheckbox-colorSecondary {
      color: #f52900;
    }

  }

  .toolbar-actions {
    align-items: center;
    display: flex;
    padding-left: 1rem;

    .selected {
      align-items: center;
      background-color: $brand-gray;
      border: 1px solid $dark-gray;
      border-radius: 5px;
      color: $white;
      display: inline-flex;
      font-family: $primary-font;
      height: 2rem;
      justify-content: center;
      margin-right: 1rem;
      text-align: center;
      width: 16rem;
    }
  }
}