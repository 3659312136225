@import '../../assets/scss/colors.scss';

.MuiDialog-root {
  .MuiDialog-container {
    .MuiDialogContent-root {
      padding: 3rem;

      .MuiDialogContentText-root {
        color: $black;
        font-size: 1.5rem;
      }

      .dialog-content {
        display: flex;
        padding: 2rem 3rem 0;

        .plans {
          font-size: 1.5rem;
          ul {
            flex-direction: column;
            list-style: disc;
          }
        }
      }
    }

    .MuiDialogActions-root {
      button {
        font-size: 1.5rem;
      }
    }
  }
}