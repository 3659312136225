.MuiDialog-root {
  .MuiDialog-container {
    .MuiDialogTitle-root {
      background-color: $brand-blue;
      color: $white;
      font-family: $primary-font-bold;
      font-size: 1.25rem;
      padding: 1rem;
      text-align: center;
      text-transform: uppercase;
    }
    .MuiDialogContent-root {
      padding-top: 1.5rem;
      .MuiDialogContentText-root {
        margin: 1rem;
        text-align: center;
      }
    }
  }
}