@import '../../assets/scss/colors.scss';
@import '../../assets/scss/fonts.scss';

.clubs-groups-container {
  display: flex;
  flex-wrap: wrap;
  padding: 60px 160px;
  justify-content: space-between;

  h5 {
    font-family: $primary-font;
    width: 100%;
  }

  a {
    background-color: $light-gray;
    border: 1px solid $brand-gray;
    border-radius: 5px;
    color: $brand-gray;
    flex: 1 1 160px;
    font-family: $primary-font;
    font-size: 24px;
    margin: 30px 20px;
    padding: 30px 20px;
    text-align: center;
    transition: all 0.3s ease-in-out;
    white-space: nowrap;

    &:hover {
      background-color: $brand-gray;
      color: $white;
      border-color: $brand-gray;
      text-decoration: none;
      transform: scale(1.05);
    }
  }
}