@import '../../assets/scss/colors.scss';
@import '../../assets/scss/fonts.scss';

.variations-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: 36rem;
  padding: 4rem;

  .plan {
    background-color: #fcfcfc;
    border-radius: 5px;
    margin: 0.75rem 0;
    padding: 3rem 2rem;

    .name {
      font-family: $primary-font-bold;
      font-size: 1.25rem;
    }

    .variations {
      display: flex;
      justify-content: space-evenly;
      margin-top: 2rem;

      a {
        color: $white;
        width: 5rem;
      }
    }


    &.blue,
    &.blue-nc {
      border: 1px solid $brand-blue;

      a {
        background-color: $brand-blue;
      }
    }

    &.green {
      border: 1px solid $brand-green;

      a {
        background-color: $brand-green;
      }
    }

    &.gray {
      border: 1px solid $brand-gray;

      a {
        background-color: $brand-gray;
      }
    }

    &.orange {
      border: 1px solid $brand-orange;

      a {
        background-color: $brand-orange;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    .plan {
      padding: 2rem 0;
    }
  }

  @media only screen and (max-width: 767.98px) {
    padding: 2rem;

    .plan {
      margin:0.25rem 0;
      padding: 0.75rem 2rem;

      .name {
        font-size: 1rem;
      }

      .variations {
        margin-top: 0.5rem;
      }
    }
  }
}